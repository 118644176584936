* {
	box-sizing: border-box;
	outline: none;
}

html {
	scroll-behavior: smooth;
	font-size: 10px;
}
body {
	font-family: $body-font;
	@include font-size(1.6, 1.5);
	font-weight: 400;
}
.bold {
	font-weight: bold;
}
img {
	max-width: 100%;
	display: block;
	height: auto;
}
.container {
	max-width: 780px;
	margin: auto;
}
.middle-align {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.button-solid {
	--background: #feec96;
	--background-hover: #feec96;
	--background-activated: #feec96;
	--background-focused: #feec96;
	--color: black;
	text-transform: none;
}
.white-button {
	--background: white;
	--background-hover: white;
	--background-activated: white;
	--background-focused: white;
	--border-color: #000;
	--border-style: solid;
	--border-width: 1px;
}
.delAccount {
	--background: transparent;
	--background-hover: white;
	--background-activated: white;
	--background-focused: white;
	--border-color: #000;
	--color: #000;
	--border-style: solid;
	--border-width: 1px;
}
.blue-button {
	--background: #0a0c43;
	--background-hover: #0d105d;
	--background-activated: #0a0c43;
	--background-focused: #0a0c43;
	--border-color: #0a0c43;
	--border-style: solid;
	--border-width: 1px;
	--color: white;
}
ion-content {
	--background: transparent;
}
ion-toolbar {
	--background: transparent;
	position: fixed;
	text-align: center;

	.share_button {
		border-radius: 50%;
		width: 56px;
		--padding-start: 0;
		--padding-end: 0;
	}
	&.relative {
		position: relative;
		--background: #feec96;
	}
	&.transparent {
		position: relative;
		--background: transparent;
		background-color: transparent;
	}
}
.header-ios ion-toolbar:last-of-type {
	--border-width: 0;
}
.header-md::after {
	background-image: none;
}
.d-inline {
	display: inline;
}
// strong {
// 	font-weight: 600;
// }
.danger {
	color: red;
}
.success {
	color: green;
}
.no-padding {
	--padding-end: 0;
	--padding-start: 0;
}
.no-end-padding {
	--padding-end: 0.5rem;
}
.no-start-padding {
	--padding-start: 0.5rem;
}
.nohpadding {
	padding: 2rem 0 !important;
}
.padding {
	padding: 0 2rem !important;
}
.margin {
	margin: 5rem 0 !important;
}
.mr-auto {
	margin: auto;
}
.header_black,
.header_black ion-menu-button {
	color: black;
}

.header_white,
.header_white ion-menu-button {
	color: white;
}
.logoMLM {
	width: 106px;
	height: 40px;
	margin-left: 2rem;
}
.yellow_button {
	--background: #feec96;
	color: black;
}
.disabled-input {
	pointer-events: none;
  }
  .emailButton{
	position: absolute;
	right:5px;
	top:0%;
	// transform: translateY(-50%);
	z-index: 2;
  }
  .relative{
	position: relative;
  }
// Rem output with px fallback
@mixin font-size($sizeValue: 1, $lineValue: 1.5) {
	// font-size: ($sizeValue * 9) * 1px;
	// line-height: ($lineValue * ($sizeValue * 9)) * 1px;
	// font-size: $sizeValue * 0.9rem;
	// line-height: $lineValue;
	// @media screen and (min-width: 37.5em) {
	font-size: ($sizeValue * 10) * 1px;
	line-height: ($lineValue * 10 * 1.5) * 1px;
	font-size: $sizeValue * 1rem;
	line-height: $lineValue;
	// }
}

@import "mixins";

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-Thin_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-Thin_Web.woff") format("woff");
// 	font-weight: 100;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-ThinItalic_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-ThinItalic_Web.woff") format("woff");
// 	font-weight: 100;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-XLight_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-XLight_Web.woff") format("woff");
// 	font-weight: 200;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-XLightItalic_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-XLightItalic_Web.woff") format("woff");
// 	font-weight: 200;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-Light_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-Light_Web.woff") format("woff");
// 	font-weight: 300;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-LightItalic_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-LightItalic_Web.woff") format("woff");
// 	font-weight: 300;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-Book_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-Book_Web.woff") format("woff");
// 	font-weight: 400;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-BookItalic_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-BookItalic_Web.woff") format("woff");
// 	font-weight: 400;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-Medium_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-Medium_Web.woff") format("woff");
// 	font-weight: 500;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-MediumItalic_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-MediumItalic_Web.woff") format("woff");
// 	font-weight: 500;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-Bold_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-Bold_Web.woff") format("woff");
// 	font-weight: 700;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-BoldItalic_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-BoldItalic_Web.woff") format("woff");
// 	font-weight: 700;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-Black_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-Black_Web.woff") format("woff");
// 	font-weight: 800;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-BlackItalic_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-BlackItalic_Web.woff") format("woff");
// 	font-weight: 800;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-Ultra_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-Ultra_Web.woff") format("woff");
// 	font-weight: 900;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "HCo Gotham";
// 	src: url("#{$fontPath}woff2/Gotham-UltraItalic_Web.woff2") format("woff2"),
// 		url("#{$fontPath}woff/Gotham-UltraItalic_Web.woff") format("woff");
// 	font-weight: 900;
// 	font-style: italic;
// 	font-display: swap;
// }

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html {
	--ion-font-family: "Montserrat", serif;
}
h1,
.h1 {
	@include font-size(4);
	font-weight: 700;
	small {
		@include font-size(2.4, 1);
		display: block;
	}
}
ion-menu ion-content {
	background: $yellow;
	--background: $yellow;
	// --color: black;
	ion-list-header {
		color: $black;
	}
}
.errormessage {
	color: $yellow;
}
ion-note#display_registrationCode{
	font-size: 16px;
	cursor: pointer;
}
.error-box{
	background-color: white;
	border-radius: 4px;
	padding:0.3rem 1rem;
	color: red;
	box-shadow: 30px 43px 50px #00000029;
	margin-top: 1rem !important;
}
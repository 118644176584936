$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1900px;

$imagePath: "../assets/img/";
$fontPath: "../assets/fonts/";

$yellow: #feec96 !default;
$white: #ffffff;
$black: #000000;
$dark_blue: #100d64;
$pink: #eb9479;

$body-font: "Montserrat", serif;
$heading-font: "Montserrat", serif;

/* Style the buttons that are used to open and close the accordion panel */
.accordion_wrapper {
	position: relative;
	background-color: $white;
	border-top: 1px solid $black;
	border-bottom: 1px solid $black;
}
.cl-accordion {
	border-bottom: 1px solid $black;
}
.accordion {
	cursor: pointer;
	padding: 1.6rem 0;
	margin: 0 1.6rem;
	width: calc(100% - 3.2rem);
	// @include font-size(2.7);
	font-family: $heading-font;
	font-weight: 700;
	text-align: left;
	border: none;
	outline: none;
	background-color: transparent;
	transition: 0.4s;
	/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $black;

	&:hover {
		background-color: rgba($white, 0.3);
	}

	&:after {
		content: ""; /* \02795 Unicode character for "plus" sign (+) */
		font-size: 13px;
		color: $yellow;
		// float: right;
		margin-left: 5px;
		width: 26px;
		height: 20px;
		background-image: url("#{$imagePath}icon/eye.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	&.active {
		+ .panel {
			margin: 0 0 2rem 0;
			hr {
				background-color: $black;
			}
		}
		&:after {
			content: ""; /* \02796 Unicode character for "minus" sign (-) */
			background-image: url("#{$imagePath}icon/eye-outline.svg");
		}
	}

	/* Style the accordion panel. Note: hidden by default */
}
.panel {
	padding: 0 1.6rem;
	white-space: pre-line;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}
.flex-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	&.center-center {
		align-items: center;
		justify-content: center;
	}
	.accordion_wrapper {
		overflow: hidden;

		> div {
			overflow: scroll;
			height: 100%;
		}
	}
}
